import type { PagesConfig } from '#types/config/pages'

export default {
  defaultSrc: 'https://locations.thenorthface.com/index.html',
  addressLine: {
    at: 'Vienna',
    be: 'Maasmechelen',
    ca: 'Toronto',
    ch: 'Bern',
    cz: 'Prague',
    de: 'Berlin',
    dk: 'Copenhagen',
    es: 'Madrid',
    fi: 'Helsinki',
    fr: 'Paris',
    gb: 'London',
    ie: 'Dublin',
    it: 'Rome',
    lu: 'Luxembourg',
    nl: 'Amsterdam',
    pl: 'Warsaw',
    pt: 'Lisbon',
    se: 'Stockholm',
    us: 'San Francisco',
  },
  localizedSrc: [
    {
      locales: [
        'de-at',
        'de-be',
        'de-ch',
        'de-de',
        'de-lu',
        'es-es',
        'fr-be',
        'fr-ca',
        'fr-ch',
        'fr-fr',
        'fr-lu',
        'it-ch',
        'it-it',
        'nl-be',
        'nl-lu',
        'nl-nl',
        'pt-pt',
        'pl-pl'
      ],
      src: 'https://hosted.where2getit.com/northfaceeu/index_eu_{lang}_responsive.html?form=locator_search'
    },
    {
      locales: ['cs-cz', 'da-dk', 'en-dk', 'en-ch', 'en-ie', 'en-fi', 'en-lu', 'en-se', 'sv-se', 'sv-fi'],
      src: 'https://hosted.where2getit.com/northfaceeu/index_eu_responsive.html?form=locator_search'
    },
    {
      locales: ['en-gb'],
      src: 'https://hosted.where2getit.com/northfaceeu/index_eu_uk_responsive.html?form=locator_search&country=UK'
    },
  ]
} satisfies PagesConfig['storeLocator']
