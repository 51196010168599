<template>
  <component :is="asChild ? Slot : 'button'" @click="onDelete(file)">
    <slot />
  </component>
</template>

<script setup lang="ts">
import { Slot } from 'radix-vue'
import { FileUploadContextKey, FileUploadItemContextKey } from './context'

defineProps<{
  asChild?: boolean
}>()

const { onDelete } = inject(FileUploadContextKey)!
const { file } = inject(FileUploadItemContextKey)!
</script>
