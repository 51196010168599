import type { DropdownConfig } from '#types/config/components/vf/dropdown'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    button: 'rounded-3xl px-4',
    buttonClosed: 'b-grey-90 bg-grey-90',
    buttonOpened: 'b-grey-70 b-b-white rounded-b-none bg-white',
    popoverContent: 'b b-grey-70'
  }
} satisfies RecursivePartial<DropdownConfig>
