import type { ColorPickerConfig } from '#types/config/components/vf/colorPicker'
import type { RecursivePartial } from '#types/utils'

export default {
  brandStyles: {
    rectangle: {
      container: {
        h: {
          sm: '3.375rem',
          md: '5.125rem',
          lg: '5.125rem'
        },
        w: {
          sm: '3rem',
          md: '4.5rem',
          lg: '4.5rem'
        }
      }
    }
  }
} satisfies RecursivePartial<ColorPickerConfig>
