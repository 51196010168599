import type { ProductConfig } from '#types/config/components/product'

export default {
  brandClasses: {
    container: 'p-4 mb-3 bg-grey-90',
    details: {
      button: 'underlined fw-normal block',
      link: 'fw-normal',
      text: 'fw-light'
    },
    title: 'text-base fw-light' // TODO: GLOBAL15-75970 define a new one or reuse one of the existing title shortcuts
  },
  checkboxSize: 'md',
  image: {
    height: 68,
    width: 68
  },
  buttonSize: 'tiny',
  buttonVariant: 'tertiary'
} satisfies ProductConfig['upsell']
