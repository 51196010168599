import type { DetailsItemConfig } from '#types/config/components/order/detailsItem'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    backToLink: 'flex items-center gap-1 <md:mb-6',
    billingSection: '<lg:pt-6 lg:pl-6',
    chevronIcon: 'md:hidden',
    containerHeading: 'mb-6 mt-8 items-center <lg:(mt-6 mb-8)',
    emphasizedText: 'fw-medium',
    estimatedDelivery: {
      container: 'mb-2',
      text: 'fw-medium text-sm'
    },
    informationSection: 'lg:(grid cols-2 divide-x) md:mb-10 divide-grey-70 <lg:divide-y',
    informationSectionDetails: 'grid cols-1 md:cols-2 gap-x-6 lg:gap-x-4 fw-light',
    itemDescriptionDetails: 'text-sm lh-1',
    itemDescriptionDetailsTitle: 'pt-0 md:pt-4',
    itemSummaryHeading: 'title-3 b-grey-50 <md:(b-t pt-8) md:(b-b pb-2)',
    orderInfoContainer: 'md:flex pb-12 pt-6',
    pricingText: '<md:flex',
    shipmentContainer: '<md:(flex between items-baseline)',
    shipmentText: 'fw-light <md:lh-4',
    shippingSection: 'pb-6 lg:pr-6',
    trackingNumber: 'text-sm'
  },
  notificationVariant: 'left',
  showEDD: true,
  overrideNarvar: {
    redirectLocale: {
      'sv-fi': 'en-fi'
    }
  }
} satisfies RecursivePartial<DetailsItemConfig>
