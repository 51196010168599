import type { RecursivePartial } from '#types/utils'
import type { PdpConfig } from '#types/config/pages/pdp'

export default {
  accordions: {
    benefitsAttributeImages: true
  },
  brandClasses: {
    ctasWrapper: 'mt-6 pb-10 space-y-4',
    details: 'lg:col-span-4 md:(col-span-5 row-start-2 row-end-5)',
    detailsSticky: 'md:pt-4',
    eSpotsAndReviews: 'space-y-8',
    gallery: '<md:-mx-2 lg:col-span-8 md:(col-span-7 row-start-1 row-end-3 pt-4)',
    grid: 'mb-16 md:(grid mb-20 items-start gap-4 cols-12)',
    gridFullWidth: 'col-span-12',
    gridPartialWidth: 'lg:col-span-8 md:col-span-7',
    heading: 'pt-4 lg:col-span-4 md:col-span-5 <lg:mb-4',
    headingSticky: 'mb-8 lg:mb-4',
    reviewsHeading: 'text-center <md:text-left',
    reviewsSearchInput: 'b-t-0 b-x-0 !b-grey-30 rounded-l-0 !bg-transparent text-base [&_label]:pl-1',
    reviewsSearchSubmit: 'b-b b-grey-30 bg-transparent pr-1',
    reviewsWrapper: '<md:space-y-4',
    shopTheLookHeading: 'md:(text-center mb-6)',
    shopTheLookWrapper: 'mt-15',
    variantSelectionWrapper: 'b-t b-grey-70 pt-6 space-y-4'
  },
  details: {
    ctaSize: { sm: 'sm', lg: 'md' },
    favoriteCta: {
      iconSize: 'md',
      iconOnly: true
    },
    findInStoreCta: {
      showIcon: false
    }
  },
  enableColorAsAttribute: true,
  gallery: {
    presets: {
      src: { width: 462, height: 462 },
      srcLarge: { width: 850, height: 850 },
      srcZoom: { width: 1300, height: 1300 },
      srcThumbnail: { width: 126, height: 126 }
    }
  },
  questions: {
    pageSize: 6
  },
  reviews: {
    variant: 'tertiary',
    iconSize: 'md',
    nonFilterKeys: ['cons', 'pros'],
    pageSize: 6,
    mostLikedCommentTextThreshold: 225,
    recommendationRateThreshold: 74,
    prosAndConsMaxNumber: 6,
    filterOptionsMaxNumber: 7,
    summaryHeight: '5.2rem'
  },
  shopTheLook: {
    showLinkArrow: true
  },
  seoMetaImage: '/img/logos/thenorthface/default.svg',
  swatches: {
    showCollapseIcon: true
  },
  upsells: {
    showButton: true,
    showCheckbox: false,
    showDetails: false
  }
} satisfies RecursivePartial<PdpConfig>
