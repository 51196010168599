import type { VfConfig } from '#types/config/components/vf'
import type { RecursivePartial } from '#types/utils'

export default {
  giftCardLocales: [
    'cs-cz',
    'da-dk',
    'de-at',
    'de-be',
    'de-de',
    'de-lu',
    'en-dk',
    'en-fi',
    'en-gb',
    'en-ie',
    'en-se',
    'en-us',
    'en-lu',
    'es-es',
    'es-us',
    'fr-be',
    'fr-fr',
    'fr-lu',
    'it-it',
    'nl-be',
    'nl-be',
    'nl-lu',
    'nl-nl',
    'pl-pl',
    'pt-pt',
    'sv-fi',
    'sv-se',
  ]
} satisfies RecursivePartial<VfConfig['utilityNavigation']>
