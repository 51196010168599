import type { PlpConfig } from '#types/config/pages/plp'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    categoryTitle: 'mb-6 mt-4 lg:my-8 title-2'
  },
  sorts: () => ({
    NORA: [
      'bestMatches',
      'priceLowToHigh',
      'priceHighToLow',
      'rating'
    ],
    EMEA: [
      'bestMatches',
      'priceLowToHigh',
      'priceHighToLow',
      'rating',
      'topSellers',
      'whatsNew'
    ]
  })
} satisfies RecursivePartial<PlpConfig>
