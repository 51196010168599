import type { VfConfig } from '#types/config/components/vf'

export default {
  brandClasses: {
    carousel: 'gap-2'
  },
  iconName: 'fullscreen',
  iconSize: 'lg',
  controlslist: 'nodownload'
} satisfies VfConfig['mediaGallery']
