import type { RecursivePartial } from '#types/utils'
import type { InvoiceConfig } from '#types/config/components/form/invoice'

export default {
  companyName: {
    includedLocales: ['it-it', 'pt-pt', 'es-es']
  },
  vatNumber: {
    includedLocales: ['it-it', 'pt-pt', 'es-es'],
    regex: {
      IT: {
        code: /^\d+$/,
        length: 11
      },
      ES: {
        code: /^[a-z]\d{8}$/i,
        length: 9
      },
      PT: {
        code: /^5\d*$/,
        length: 9
      }
    }
  },
  pecEmail: {
    includedLocales: ['it-it']
  },
  recipientCode: {
    includedLocales: ['it-it']
  },
  fiscalCode: {
    includedLocales: ['it-it', 'pt-pt', 'es-es'],
    regex: {
      IT: {
        code: /^[a-z]{6}\d{2}[a-z]\d{2}[a-z]\d{3}[a-z]$/i,
        length: 16
      },
      ES: {
        code: /^\d{9}|([a-z]\d{7}[a-z])$/i,
        length: 9
      },
      PT: {
        code: /^[1-3]\d*$/,
        length: 9
      }
    }
  },
  shouldMatchBillingAddressCountry: ['it', 'pt', 'es'],
  shouldShowInvoiceRequiredFieldsPrompt: ['it'],
  countryCodeTranslationList: ['it', 'pt', 'es']
} satisfies RecursivePartial<InvoiceConfig>
