/*
- The value we send has to match the backend key. They are used for tagging content with email preferences and user segmenting so we cannot change them.
- In 1.5 this is fully configured in the CMS but we are decoupling this for The North Face.
- There is also no endpoint to retrieve these options so we are configuring them here.
- Keeping this as a keys until we have confirmation this will be pulled from the CMS at some point
- These keys are mapped exactly to the translation files to interestsOptions object
*/

import type { InterestsConfig } from '#types/config/components/form/interests'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    wrapper: 'flex gap-2 wrap mt-4',
    wrapperDivided: 'flex gap-2 wrap mt-4'
  },
  component: 'toggle',
  showGenderOption: true,
  showOptionsLead: true,
  showSkipLink: true
} satisfies RecursivePartial<InterestsConfig>
