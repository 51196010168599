<template>
  <component :is="asChild ? Slot : 'button'" @click="onClick">
    <slot />
  </component>
</template>

<script setup lang="ts">
import { Slot } from 'radix-vue'
import { FileUploadContextKey } from './context'

defineProps<{
  asChild?: boolean
}>()

const { inputRef } = inject(FileUploadContextKey)!

const onClick = () => {
  const input = inputRef.value
  if (input)
    input.click()
}
</script>
