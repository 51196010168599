import type { AccountConfig } from '#types/config/pages/account'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    heading: 'mt-10 mb-8 title-1',
    navigation: '<lg:b-t',
    navItem: 'hover:underlined fw-light',
    navItemActive: 'underlined fw-medium',
  },
  orderHistory: {
    brandClasses: {
      grid: 'gap-4 pb-4 md:(grid gap-y-0 pb-6) lg:pb-8',
      itemsDetail: {
        attributes: 'mt-2',
        container: 'mt-4 text-sm <md:hidden <lg:mt-2',
        grid: 'grid gap-2 md:gap-4 <md:(pr-2 pt-2)',
        hiddenItemsCount: 'bg-grey-80 fw-medium text-base md:text-md',
        name: 'fw-medium'
      },
      notification: 'col-span-2 mb-4 lg:mb-6',
      orderDate: 'pt-4 fw-medium',
      viewOrderDetailsButton: 'mt-2'
    },
    brandStyles: {
      'grid-cols': {
        lg: '16.25rem 1fr'
      }
    },
    image: {
      height: { lg: 344, md: 109 },
      width: { lg: 344, md: 109 }
    },
    itemsCount: {
      md: 3
    },
    notificationVariant: 'left'
  }
} satisfies RecursivePartial<AccountConfig>
