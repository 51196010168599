import type { InjectionKey } from 'vue'

interface FileUploadContext {
  inputRef: Ref<HTMLInputElement | null>
  files: Ref<File[]>
  onDelete: (file: File) => void
}

interface FileUploadItemContext {
  file: Ref<File>
}

export const FileUploadContextKey: InjectionKey<FileUploadContext> = Symbol('FileUploadContext')
export const FileUploadItemContextKey: InjectionKey<FileUploadItemContext> = Symbol('FileUploadItemContext')
