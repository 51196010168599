export default {
  NORA: {
    baseUrl: {
      DEV: 'https://d3bp57v33hncyl.cloudfront.net',
      QA: 'https://dpnuyk3swzpxc.cloudfront.net',
      PREPROD: 'https://d2ja1t4z0bvzku.cloudfront.net',
      PROD: 'https://d3fwuswwhi7ss1.cloudfront.net'
    },
    apiDomain: {
      DEV: 'https://tnf-staging.demostellar.com',
      QA: 'https://tnf-qa.demostellar.com',
      PREPROD: 'https://tnf-qa.demostellar.com',
      PROD: 'https://tnf.runstellar.com'
    },
    cssUrl: '/tnfnora/tnfnora-webapp.style.css',
    jsUrl: '/loyalty-webapp.bundle.js',
    configUrl: '/tnfnora/app-config.json'
  },
  EMEA: {
    baseUrl: {
      DEV: 'https://ds8s3etqpk8ol.cloudfront.net',
      QA: 'https://d1h1e9rt6vsq3c.cloudfront.net',
      PREPROD: 'https://dghi27fisgxty.cloudfront.net',
      PROD: 'https://d3kvd9ktmn6p4t.cloudfront.net'
    },
    apiDomain: {
      DEV: 'https://tnfemea-staging.demostellar.com',
      QA: 'https://tnfemea-staging.demostellar.com',
      PREPROD: 'https://tnfemea-staging.demostellar.com',
      PROD: 'https://tnfemea.runstellar.com'
    },
    cssUrl: '/tnfcanvasemea/tnfcanvasemea-webapp.style.css',
    jsUrl: '/loyalty-webapp.bundle.js',
    configUrl: '/tnfcanvasemea/app-config.json'
  }
}
