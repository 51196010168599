import type { ProductConfig } from '#types/config/components/product'
import type { RecursivePartial } from '#types/utils'

export default {
  badgePosition: 'bottom',
  brandClasses: {
    badge: 'm-2 shadow-sm <md:hidden',
    eyebrow: 'my-1',
    gallery: 'mb-3',
    quickshopCta: 'self-start',
    swatchCount: 'my-1',
    title: 'text-sm my-1 fw-light'
  },
  maxCarouselItems: 3,
  maxGalleryItems: 3,
  mediaSize: {
    width: 344,
    height: 344
  },
  quickshopCta: {
    size: 'tiny'
  }
} satisfies RecursivePartial<ProductConfig['card']>
