import type { VfConfig } from '#types/config/components/vf'

export default {
  brandClasses: {
    header: 'bg-grey-90 fw-normal',
    row: 'px-2 py-3',
    th: 'b b-grey-70',
    thXStuck: 'b-r b-grey-50',
    thYStuck: 'b-b b-grey-50',
    td: 'b b-grey-70',
    tdStuck: 'b-r b-grey-50'
  }
} satisfies VfConfig['table']
