import type { RecursivePartial } from '#types/utils'
import type { TabsConfig } from '#types/config/components/vf/tabs'

export default {
  brandClasses: {
    primary: {
      triggerContainer: ['!b-b-grey-50'],
      trigger: ['pb-3 fw-medium'],
      triggerActive: '!b-b-grey-10'
    }
  }
} satisfies RecursivePartial<TabsConfig>
