import type { RecursivePartial } from '#types/utils'
import type { SignUpConfig } from '#types/config/components/dialog/signUp'

export default {
  showSignInCta: false,
  isInterestsDependingOnNewsletter: true,
  toastCtaLoyaltyLink: {
    requiredLocales: [
      'en-gb',
      'de-de',
      'es-es',
      'fr-fr',
      'en-ie',
      'it-it',
      'nl-nl'
    ]
  }
} satisfies RecursivePartial<SignUpConfig>
