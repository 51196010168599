import type { RecursivePartial } from '#types/utils'
import type { DashboardConfig } from '#types/config/components/dashboard'

export default {
  brandClasses: {
    cta: '<md:w-full',
    header: 'title-4 pt-3 pb-2'
  },
  favorites: {
    image: {
      height: 88,
      width: 88,
    },
    thumbnail: {
      height: 40,
      width: 40,
    },
  }
} satisfies RecursivePartial<DashboardConfig['card']>
