import type { RecursivePartial } from '#types/utils'
import type { KlarnaConfig } from '#types/config/components/klarna'

export default {
  brandClasses: {
    disclaimerText: 'fw-light text-sm',
    introText: 'mb-6 fw-light text-base',
    logo: 'ml-1'
  },
  installments: {
    'en-gb': 3,
    'es-es': 3,
    'fr-fr': 3,
    'it-it': 3
  },
  logo: {
    pdpDialog: 'klarna-pink'
  },
  showLogoHeader: true
} satisfies RecursivePartial<KlarnaConfig>
