import type { StoreIdsConfig } from '#types/config/storeIdsConfig'

export default {
  storeId: {
    at: 7011,
    be: 7014,
    ca: 7002,
    ch: 7018,
    cz: 7021,
    de: 7007,
    dk: 7015,
    es: 7009,
    fi: 7016,
    fr: 7006,
    gb: 7005,
    ie: 7012,
    it: 7008,
    lu: 7017,
    nl: 7010,
    pl: 7020,
    pt: 7019,
    se: 7013,
    us: 7001
  }
} as StoreIdsConfig
