import type { CheckoutConfig } from '#types/config/checkout'

export default {
  NORA: {
    siteId: {
      DEV: '09c23d645068',
      QA: '09c23d645068',
      PREPROD: '09c23d645068',
      PROD: '09c23d645068'
    }
  }
} satisfies CheckoutConfig['forter']
