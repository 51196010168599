<template>
  <component :is="asChild ? Slot : 'div'">
    <slot />
  </component>
</template>

<script setup lang="ts">
import { Slot } from 'radix-vue'

import { FileUploadItemContextKey } from './context'

const props = defineProps<{
  file: File
  asChild?: boolean
}>()

provide(FileUploadItemContextKey, toRefs(props))
</script>
