import type { VfConfig } from '#types/config/components/vf'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    iconWrapper: 'h-full',
    logo: 'h-8 lg:h-10',
    primaryNavigation: 'gap-3 py-4 lg:(items-end py-2)',
    utilityNavigation: 'gap-6 pt-3'
  },
  excludeStickyRoute: (() => []) as unknown as VfConfig['header']['excludeStickyRoute'],
  isStickyPromoBarEnabled: true,
  showOnScrollUp: true
} satisfies RecursivePartial<VfConfig['header']>
