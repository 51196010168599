import type { RecursivePartial } from '#types/utils'
import type { VfConfig } from '#types/config/components/vf'

export default {
  defaultSearchTerm: 'Jacket',
  iconSize: 'md',
  brandClasses: {
    input: 'b-0 rounded-l-none [&_label]:pl-1 bg-transparent',
    inputTransparent: '[&_input]:(placeholder-white c-white)',
    form: 'b-b <lg:bg-white',
    button: '',
    buttonTransparent: 'bg-transparent b-0',
    suggestions: {
      wrapper: ''
    }
  }
} satisfies RecursivePartial<VfConfig['search']>
