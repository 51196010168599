import type { ProductConfig } from '#types/config/components/product'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    filterAndSortCta: 'b-transparent bg-grey-90 px-8 fw-light',
    innerGridWrapper: 'pb-10',
    noResults: 'mb-6 mt-16 text-center lg:mt-8 title-2',
    pagination: 'mt-10'
  },
  filterAndSortCta: {
    iconSize: 'md'
  },
  grid: {
    gaps: {
      y: 'md'
    }
  }
} satisfies RecursivePartial<ProductConfig['list']>
